import { useQuery } from "react-query";
import { productAPI, productDetailsAPI, productWithPaymentTermsAPI } from "api/product";
import { useNavigate } from "react-router-dom";
import { useProductsSearchValuesStore } from "stores/useSearchValuesStore";

// This is a custom hook that uses useMutation to fetch Product data and return it in response. 
// This hook is then used in Product component to sign the user in using the signIn function from useSignIn hook. 
// It also navigates the user to the homepage and shows a toast message.

const useFetchProduct = (searchValue,searchFilters,options) =>  {
    
    // const signIn = useSignIn();
    const navigate = useNavigate();
    const setPaginationConfig = useProductsSearchValuesStore( state => state.setPaginationConfig);
    const pageNumber = useProductsSearchValuesStore( state =>  state.pageNumber);
    const userPriceFilterSet = useProductsSearchValuesStore(state => state.userPriceFilterSet);
    
    return useQuery(['products',searchValue,searchFilters,pageNumber],
      () => preFetchProduct(searchValue,searchFilters,pageNumber ,userPriceFilterSet),
        {
          select: data => {
            const responseData = data.data
            const allProducts = responseData.data.map((product) => ({
              // res.data
              id: product.id,
              image: product.image,
              name: product.name,
              // price: product.list_price,
              //priceAfterDiscount: product.list_price,
              price_after_discount : product.website_price,
              difference: product.website_price_difference,
              original_price : product.website_public_price,
              // discount: 50,
              rating: product.rating,
            }))
            // debugger
            //console.log(responseData)
            setPaginationConfig(responseData)
            return allProducts
          }
          ,
            ...options
        }
        )
}

const useFetchProductDetails = (id,options) =>  {
    
  // const signIn = useSignIn();
  // const navigate = useNavigate();
  // const setPaginationConfig = useProductsSearchValuesStore( state => state.setPaginationConfig)

  return useQuery(['products-details',id],
  () => preFetchProductDetails(id),
      {
        select: data => {
          const responseData = data.data
          // const allProducts = responseData.data.map((product) => ({
  
          // debugger
          // setPaginationConfig(responseData)
          return responseData
        },
          ...options
      }
      )
}



const useFetchProductWithPayment = (id, data ,options) =>  {
  return useQuery(['products-with-payment',id,data],
    () => preFetchProductWithPayment(id, data),
      {
        select: data => {
          const responseData = data.data
          return responseData
        }
        ,
          ...options
      }
      )
}


const preFetchProductWithPayment = (id, data )=>{
    return productWithPaymentTermsAPI(id, data )
}


// The function is named preFetchProduct
// The function takes in a parameter called params
// The function returns the output of the ProductAPI function

const preFetchProduct = (searchValue, searchFilters, pageNumber,userPriceFilterSet) => {
  const data = {
    "search": searchValue,
    "public_categ_ids": searchFilters.categories,
    "page": pageNumber,
  };
  const hasValidPriceFilter = userPriceFilterSet &&
                              searchFilters.priceFilter &&
                              typeof searchFilters.priceFilter === 'object' &&
                              searchFilters.priceFilter.min !== undefined &&
                              searchFilters.priceFilter.max !== undefined;

  if (hasValidPriceFilter) {
    data.price = {
      from: searchFilters.priceFilter.min,
      to: searchFilters.priceFilter.max
    };
  } else if (userPriceFilterSet) {
    data.price = {
      from: 1, 
      to: Infinity 
    };
  }
  return productAPI(data);
}

const preFetchProductDetails = (id) => {
  return productDetailsAPI(id)
}

const ProductService = {
  useFetchProduct,
  useFetchProductDetails,
  useFetchProductWithPayment
};

export default ProductService;

