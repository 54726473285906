// STYLES
import styles from "./CustomRadioBtn.module.scss";

const CustomRadioBtn = ({ ...props }) => {
	const installmentAction = (id , index) => {	
		if (typeof props?.ActivateLineIds === 'function') {
			props?.ActivateLineIds(index)
		}
		if (typeof props?.SetInstallmentsActive === 'function') {
			props?.SetInstallmentsActive(index)
		}
		if (typeof props?.getId === 'function') {
			props?.getId(id)
		}
	}
	return (
		<label className={styles.custom_radioBtn}>
			<input type="radio" name={props.name} value={props.value} checked={props.active} />
			{!props.installmentLbl ? (
				<div className={styles.txt}>{props.label}</div>
			) : (
				<div className={`${styles.txt} ${styles.installment_txt}`} onClick={()=>installmentAction(props.id , props.index)}>
					<span>{props.label}</span>
					<span>Months</span>
				</div>
			)}
		</label>
	);
};

export default CustomRadioBtn;
