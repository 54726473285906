import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
// Utils
import { signup } from "constants/Images";
import { signupValidationSchema } from "constants/Schema";
import { getFormErrorMessage } from "helpers/getFormErrorMessage";
// COMPONENTS
import { PasswordInput, TextInput } from "components/Form/Inputs";
import GradientButton from "components/UI/GradientButton/GradientButton";

// SERVICES
import SignUpService from "features/Auth/SignupService";

// STYLES
import styles from "./SignUp.module.scss";

const SignUp = () => {
	const { isLoading: signupLoading, mutate: signupAction } =
		SignUpService.useFetchSignUp();

	const onSubmit = async (values) => {
		const { isError } = await signupAction(values);

		if (!isError) formik.resetForm();
	};

	const formik = useFormik({
		initialValues: {
			fullName: "",
			email: "",
			phoneNumber: "",
			password: "",
			confirmPassword: "",
		},
		validateonkeyup: true,
		onSubmit,
		validationSchema: signupValidationSchema,
	});

	return (
		<div className={styles.log_page}>
			<div className="row">
				<div className="col-lg-6">
					<div className={styles.log_left}>
						<img src={signup} alt="" className={styles.log_img} />
						<h2>Receive payments from any banking system</h2>
						<p>
							Connect your bank card, and create accounts in the selected
							currency.
						</p>
					</div>
				</div>
				<div className="col-lg-6">
					<div className={styles.log_form}>
						<img src="/img/signup-shop.svg" alt="" className={styles.emoji} />
						<div className={styles.log_form_head}>
							<h1>Join us</h1>
							<p>we are happy to see you here</p>
						</div>
						<form onSubmit={formik.handleSubmit}>
							<div className={styles.inputs_wrapper}>
								{/* Full Name */}
								<TextInput
									inputLabel="Full name"
									inputName="fullName"
									placeHolder="Type your full name"
									inputVal={formik.values.fullName}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage(formik, "fullName")}
									handleBlur={formik.handleBlur}
								/>

								{/* Email */}
								<TextInput
									inputLabel="E-mail"
									inputName="email"
									placeHolder="Type your e-mail"
									inputVal={formik.values.email}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage(formik, "email")}
									handleBlur={formik.handleBlur}
								/>

								{/* Phone Number */}
								<TextInput
									inputLabel="Phone number"
									inputName="phoneNumber"
									placeHolder="Type your phone number"
									inputVal={formik.values.phoneNumber}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage(formik, "phoneNumber")}
									handleBlur={formik.handleBlur}
								/>

								{/* Password */}
								<PasswordInput
									inputLabel="Password"
									inputName="password"
									placeHolder="Type your pasword"
									inputVal={formik.values.password}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage(formik, "password")}
									handleBlur={formik.handleBlur}
								/>

								{/* Confirm Password */}
								<PasswordInput
									inputLabel="Confirm New Password"
									inputName="confirmPassword"
									placeHolder="Type confirm new password"
									inputVal={formik.values.confirmPassword}
									changeHandler={formik.handleChange}
									inputError={getFormErrorMessage(formik, "confirmPassword")}
									handleBlur={formik.handleBlur}
								/>
							</div>
							<GradientButton
								btn
								loading={signupLoading}
								disabled={!formik.isValid}
								type="submit"
								label="Sign Up"
							/>
						</form>
						{/* Have Account */}
						<div className={styles.have_account}>
							<span>Already have an account?</span>
							<Link to="/login">Login</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
