import { TabView, TabPanel } from "primereact/tabview";

// Child Components
import Description from "./components/Description/Description";
import Reviews from "./components/Reviews/Reviews";

// STYLES
import styles from "./DescReviews.module.scss";
import ProductRating from "features/Products/ProductRating";
import { useEffect, useState } from "react";

const DescReviews = ({description , rating , productId , subject , refetchRating}) => {
	const [pageNumber , setPageNumber] = useState(1)
	const [rates , setRates ] = useState(rating);
	const {data , refetch} = ProductRating.useFetchProductRating(productId, pageNumber)
	
	const loadMore = () => {
		setPageNumber(prevPageNumber => prevPageNumber + 1);
	};
	const onAddReview = async() => {
		await refetch();
		await refetchRating();
	}

	useEffect(()=>{
		setRates(rating);
	},[rating]);

	return (
		<div className={styles.desc_reviews}>
			<TabView>
				<TabPanel header="Description">
					<div className={styles.description_body}>
						{description ? <Description description={description} /> : null}
					</div>
				</TabPanel>
				<TabPanel header="Reviews">
					<div className={styles.reviews_body}>
						<Reviews onAddReview={onAddReview} rates={rates} productId={productId} subject={subject} loadMore={loadMore} data={data} />
					</div>
				</TabPanel>
			</TabView>
		</div>
	);
};

export default DescReviews;
