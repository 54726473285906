// Child Components
import ProductsSlider from "./components/ProductsSlider/ProductsSlider";

// STYLES
import styles from "./ProductsSection.module.scss";

const ProductsSection = () => {
	return (
		<div className={styles.prodcuts_section}>
			<div className="container">
				<div className={styles.productsSec_content}>
					<h2>Products</h2>
					<p>Here you can see our products</p>

					{/* Slider */}
					<ProductsSlider />
				</div>
			</div>
		</div>
	);
};

export default ProductsSection;
