import axios from "lib/axios"
import multipartAxios from "lib/multipartAxios";
import Cookies from "js-cookie";


export const profileAPI = async (params) => {
    
    return await axios.post("/users/me", params)
}

export const updateProfileAPI = async (params) => {
    const session_id = Cookies.get("front_session_id");
    return await axios.post(`/users/update/me?session_id=${session_id}`, params);
}


export const partnersAPI = async (params) => {
    
    return await axios.post("/partners", params)
}


export const getUserDocuments = async (params) => {
    return await axios.post("/users/me/documents", params);
}

export const uploadUserDocuments = async (params) => {
    return await multipartAxios.post("/users/me/documents/upload", params);
}

export const deleteUserDocuments = async(params) => {
    return await axios.post("/users/me/documents/delete", params);
}