export const en = {
	home: "Home",
	shop: "Shop",
	products: "Products",
	offers: "Offers",
	contactUs: "Contact Us",
};

export const ar = {
	home: "الرئيسية",
	shop: "المتجر",
	products: "المنتجات",
	offers: "العروض",
	contactUs: "تواصل معنا",
};
