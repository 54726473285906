import { useState, useEffect, useCallback } from "react";
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

// COMPONENTS
import {
	CheckBox,
	ColorRadioBtn,
	FilterRange,
	SearchInput,
} from "components/Form/Inputs";
import DarkButton from "components/UI/DarkButton/DarkButton";
import GradientButton from "components/UI/GradientButton/GradientButton";

// Child Components
import FilterCollapse from "./components/FilterCollapse/FilterCollapse";

// STYLES
import styles from "./FilterSideBar.module.scss";
// import CategoryService from "features/Category/CategoryService";
import CategoryService from "features/category/CategoryService";
import { useProductsSearchValuesStore } from "stores/useSearchValuesStore";

const FilterSideBar = () => {
	// Categories
	const location = useLocation();

	const [selectedCategories, setSelectedCategories] = useState([]);

	const min_price = useProductsSearchValuesStore(state => state.min_price);
	const max_price = useProductsSearchValuesStore(state => state.max_price);
	const setPriceFilterField = useProductsSearchValuesStore(state => state.setPriceFilter);
	const setCategoriesFields = useProductsSearchValuesStore(
		(state) => state.setCategoriesFields
		);
	const resetPriceFilter = useProductsSearchValuesStore(state => state.resetPriceFilter);		
	const resetSearchValue = useProductsSearchValuesStore(state => state.updateSearchValue);
	const resetSearchInput = useProductsSearchValuesStore(state => state.resetSearchInputValue);

	const [priceFilter, setPriceFilter] = useState({ min: min_price, max: max_price });

		// Colors
		const colors = [
			{
				name: "Red",
				key: "red",
			},
			{
				name: "Green",
				key: "green",
			},
			{
				name: "Gray",
				key: "gray",
			},
			{
				name: "Black",
				key: "black",
			},
			{
				name: "Blue",
				key: "blue",
			},
			{
				name: "White",
				key: "white",
			},
		];
	
		const [selectedColor, setSelectedColor] = useState(null);
	
		// Devices
		const devices = [
			{
				name: "Phones",
				key: "phones",
			},
			{
				name: "Laptops",
				key: "laptops",
			},
			{
				name: "Printers",
				key: "printers",
			},
			{
				name: "Accessories",
				key: "accessories",
			},
			{
				name: "Sound Devices",
				key: "soundDevices",
			},
			{
				name: "Monitors",
				key: "monitors",
			},
		];

	const debouncedSetPriceFilterField = useCallback(_.debounce((newPrice) => {
		setPriceFilterField(newPrice.min, newPrice.max);
	}, 200),[]);
	
	const handlePriceChange = (newPrice) => {
		const newPriceFilter = { min: newPrice, max: priceFilter.max };
		setPriceFilter(newPriceFilter); 
		debouncedSetPriceFilterField(newPriceFilter);
	  };

	const {
		isLoading: isCategoriesLoading,
		isFetching: isCategoriesFetching,
		data: allCategories,
	} = CategoryService.useFetchCategories();

	const onCategoryChange = (e) => {
		let _selectedCategories = [...selectedCategories];

		if (e.checked) {
			_selectedCategories.push(e.value);
		} else {
			for (let i = 0; i < _selectedCategories.length; i++) {
				const selectedCategory = _selectedCategories[i];

				if (selectedCategory.key === e.value.key) {
					_selectedCategories.splice(i, 1);
					break;
				}
			}
		}

		setSelectedCategories(_selectedCategories);
		setCategoriesFields(_selectedCategories);
	};



	const [selectedDevices, setSelectedDevices] = useState([]);

	const onDeviceChange = (e) => {
		let _selectedDevices = [...selectedDevices];

		if (e.checked) {
			_selectedDevices.push(e.value);
		} else {
			for (let i = 0; i < _selectedDevices.length; i++) {
				const selectedDevices = _selectedDevices[i];

				if (selectedDevices.key === e.value.key) {
					_selectedDevices.splice(i, 1);
					break;
				}
			}
		}

		setSelectedDevices(_selectedDevices);
	};

	const resetFilters = () => {
		// reset price filter :
        setPriceFilter({ min: min_price, max: max_price }); 
        setPriceFilterField(min_price, max_price);
        resetPriceFilter();

		// reset categories :
		setSelectedCategories([]);
		setCategoriesFields([]);

		// reset search values :
		resetSearchValue("");
		resetSearchInput();
    };

	useEffect(() => {
		return () => {
			debouncedSetPriceFilterField.cancel();
		};
	}, []);

	useEffect(()=>{
		setPriceFilter({min: min_price, max: max_price})
	},[min_price, max_price]);

	useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const categoryQueryParams = searchParams.getAll('categories');

        const categoriesFromUrl = categoryQueryParams.map(categoryKey => ({ key: Number(categoryKey) }));
        setSelectedCategories(categoriesFromUrl);
    }, [location.search , location.pathname]);

	return (
		<div className={styles.filter_holder}>
			<div className={styles.head}>
				<h2>Products Filter</h2>
				<img src="/img/filter.svg" alt="" />
			</div>
			<div className={styles.filter_body}>
				{/* Search */}
				<div className={styles.filter_box}>
					<FilterCollapse title="Search">
						<SearchInput placeHolder="Search product" />
						<div className={styles.search_keys}>

						</div>
					</FilterCollapse>
				</div>

				{/* Categories */}
				<div className={styles.filter_box}>
					<FilterCollapse title="Categories">
						{isCategoriesLoading || isCategoriesFetching
							? "loading..."
							: allCategories.map((category) => (
									<CheckBox
										name="categories"
										data={category}
										value={category}
										handleChange={onCategoryChange}
										checked={(() => {
											const isChecked = selectedCategories.some(selectedCategory => selectedCategory.key === category.key);
											return isChecked;
										})()}
										key={category.key}
									/>
							  ))}
					</FilterCollapse>
				</div>

				{/* Price Filter */}
				<div className={styles.filter_box}>
					<FilterCollapse title="Price filter">
						<FilterRange 	from={min_price}
										to={max_price}
										onPriceChange={handlePriceChange} />
					</FilterCollapse>
				</div>

				{/* Color */}
				<div className={styles.filter_box}>
					<FilterCollapse title="Color">
						{colors.map((color) => (
							<ColorRadioBtn
								name="colors"
								data={color}
								value={color}
								handleChange={(e) => setSelectedColor(e.value)}
								checked={selectedColor && selectedColor.key === color.key}
								key={color.key}
							/>
						))}
					</FilterCollapse>
				</div>

				{/* Devices */}
				<div className={styles.filter_box}>
					<FilterCollapse title="Devices">
						{devices.map((device) => (
							<CheckBox
								name="categories"
								data={device}
								value={device}
								handleChange={onDeviceChange}
								checked={
									selectedDevices &&
									selectedDevices.some((item) => item.key === device.key)
								}
								key={device.key}
							/>
						))}
					</FilterCollapse>
				</div>
			</div>

			{/* Filter Actions */}
			<div className={styles.filter_footer}>
				<DarkButton btn type="button" label="Clear" outlined buttonClicked={resetFilters}/>
				<GradientButton btn label="Apply" />
			</div>
		</div>
	);
};

export default FilterSideBar;
