import { Link } from "react-router-dom";

import OtpInput from "react-otp-input";

// Utils
import { phone } from "constants/Images";

// COMPONENTS
import GradientButton from "components/UI/GradientButton/GradientButton";

// STYLES
import styles from "./OtpVerification.module.scss";
import { useState } from "react";

const OtpVerification = () => {
	const [otp, setOtp] = useState("");

	const handleSubmit = (event) => {
		event.preventDefault();
		alert(otp);
	};

	return (
		<div className={styles.log_page}>
			<div className="row">
				<div className="col-lg-6">
					<div className={styles.log_left}>
						<img src="/img/login.png" alt="" className={styles.log_img} />
						<h2>Receive payments from any banking system</h2>
						<p>
							Connect your bank card, and create accounts in the selected
							currency.
						</p>
					</div>
				</div>
				<div className="col-lg-6">
					<div className={styles.log_form}>
						<img src="/img/bell.svg" alt="" className={styles.emoji} />
						<div className={styles.log_form_head}>
							<h1>Two-Step Verification</h1>
							<img src={phone} alt="" className={styles.phone_icon} />
							<div className={styles.verification_code}>
								<h5>Enter the verification code we sent to</h5>
								<p>+38076767677</p>
							</div>
						</div>
						<form onSubmit={handleSubmit}>
							<div
								className={`${styles.inputs_wrapper} ${styles.otp_input_holder}`}
							>
								{/* Otp */}
								<label className={styles.otp_label}>Enter 4 digit code</label>
								<OtpInput
									value={otp}
									onChange={setOtp}
									numInputs={4}
									renderSeparator={<span>-</span>}
									renderInput={(props) => <input {...props} />}
									containerStyle={styles.otp_input}
									placeholder="0000"
									shouldAutoFocus
								/>
							</div>

							{/* disabled={otp.length < numInputs} */}
							<GradientButton
								btn
								type="submit"
								label="Submit"
								disabled={otp.length < 4}
							/>
						</form>
						{/* Have Account */}
						<div className={styles.have_account}>
							<span>Didn’t gate the code?</span>
							<Link to="">Resend</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OtpVerification;
