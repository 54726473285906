import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

// COMPOENTS
import Pagination from "components/UI/Pagination/Pagination";

// STYLES
import styles from "./MyOffers.module.scss";

const MyOffers = () => {
	// Offers Table Data
	const offers = [
		{
			id: 1,
			name: "Mac Book Pro Laptop",
			createdAt: "20 May 2022",
		},
		{
			id: 2,
			name: "Head Phone",
			createdAt: "20 May 2022",
		},
		{
			id: 3,
			name: "LG LDEA",
			createdAt: "20 May 2022",
		},
		{
			id: 4,
			name: "LG LDEA",
			createdAt: "20 May 2022",
		},
		{
			id: 5,
			name: "Head Phone",
			createdAt: "20 May 2022",
		},
		{
			id: 6,
			name: "Mac Book Pro Laptop",
			createdAt: "20 May 2022",
		},
		{
			id: 7,
			name: "Mac Book Pro Laptop",
			createdAt: "20 May 2022",
		},
	];

	return (
		<div className={styles.offers_table}>
			{offers && offers.length > 0 ? (
				<DataTable value={offers} responsiveLayout="scroll">
					<Column field="name" header="Name"></Column>
					<Column field="createdAt" header="Created At"></Column>
				</DataTable>
			) : null}
			<Pagination />
		</div>
	);
};

export default MyOffers;
