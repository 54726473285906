import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
// Utils
import { login } from "constants/Images";
import { loginValidationSchema } from "constants/Schema";
import { getFormErrorMessage } from "helpers/getFormErrorMessage";
// COMPONENTS
import { PasswordInput, TextInput } from "components/Form/Inputs";
import GradientButton from "components/UI/GradientButton/GradientButton";

// SERVICES
import LoginService from "features/Auth/LoginService";

// STYLES
import styles from "./LogIn.module.scss";

const LogIn = () => {
	const [error, setError] = useState(null);

	const { isLoading: loginLoading, mutate: loginAction } =
		LoginService.useFetchLogin();

	const onSubmit = async (values) => {
		setError(null);
		const { isError } = await loginAction(values);
		if (!isError) formik.resetForm();
		// console.log("test");

	};

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validateOnBlur: true,
		onSubmit,
		validationSchema: loginValidationSchema,
	});

	return (
		<div className={styles.log_page}>
			<div className="row">
				<div className="col-lg-6">
					<div className={styles.log_left}>
						<img src={login} alt="" className={styles.log_img} />
						<h2>Receive payments from any banking system</h2>
						<p>
							Connect your bank card, and create accounts in the selected
							currency.
						</p>
					</div>
				</div>
				<div className="col-lg-6">
					<div className={styles.log_form}>
						<img src="/img/waving-hand.svg" alt="" className={styles.emoji} />
						<div className={styles.log_form_head}>
							<h1>Welcome!</h1>
							<p>Please login to access your account.</p>
						</div>
						<form onSubmit={formik.handleSubmit}>
							<div className={styles.inputs_wrapper}>
								{/* Email */}
								<TextInput
									inputLabel="E-mail or phone number"
									inputName="email"
									placeHolder="Type your e-mail or phone number"
									inputVal={formik.values.email}
									changeHandler={formik.handleChange}
									handleBlur={formik.handleBlur}
									inputError={getFormErrorMessage(formik, "email")}
								/>

								{/* Password */}
								<PasswordInput
									inputLabel="Password"
									inputName="password"
									placeHolder="Type your pasword"
									inputVal={formik.values.password}
									changeHandler={formik.handleChange}
									handleBlur={formik.handleBlur}
									state = "login"
									inputError={getFormErrorMessage(formik, "password")}
								/>
							</div>
							<Link to="/forget-password" className={styles.forget_pass}>
								Forgot password?
							</Link>
							<GradientButton
								btn
								type="submit"
								loading={loginLoading}
								disabled={!formik.isValid}
								label="Login"
							/>
						</form>
						{/* Have Account */}
						<div className={styles.have_account}>
							<span>Don't have an account?</span>
							<Link to="/sign-up">Sign Up</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LogIn;
