import { QueryClient, QueryClientProvider } from 'react-query';
import { useErrorHandling } from 'hooks/useErrorHandling';

export const ReactQueryProvider = ({ children }) => {
  const handleErrors = useErrorHandling();
  
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: handleErrors,
        refetchOnWindowFocus: false,
      },
      mutations: {
        onError: handleErrors,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};