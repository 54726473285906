import axios from "axios";
import Cookies from "js-cookie";

const multipartAxios = axios.create({
    baseURL: "https://staging.finestoneco.com/api",
    headers: {
        Accept: "application/json",
    },
});

multipartAxios.interceptors.request.use((config) => {
    const session_id = Cookies.get("front_session_id");
    config.params = config.params || {};
    config.params["session_id"] = session_id;
    return config;
});

export default multipartAxios;