import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

export const useErrorHandling = () => {
  const navigate = useNavigate();

  const handleErrors = (err) => {
    if (err.response?.status === 401) {
        sessionStorage.clear();
        localStorage.clear();
        Cookies.remove('front_session_id');
        Cookies.remove('session_id');
        const message = err.response?.data?.message ? err.response.data.message : err.message;
        toast.error(message);
      navigate('/login');
    } else {
      const message = err.response?.data?.message ? err.response.data.message : err.message;
      toast.error(message);
    }
  };

  return handleErrors;
};