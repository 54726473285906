// STYLES
import styles from "./Rank.module.scss";

const Rank = ({ rankVal }) => {
	return (
		<div className={styles.rate}>
			<span>{rankVal}</span>
			<img src="/img/star.svg" alt="" />
		</div>
	);
};

export default Rank;
