import { useQuery } from "react-query";
import { paymentTermsAPI } from "api/paymentTerms";
import { usePaymentTerms } from "stores/usePaymentTerms";

const useFetchPaymentTerms = (options) =>  {
    const {setPaymentTerms} = usePaymentTerms()
    return useQuery('PaymentTerms',
      () => preFetchPaymentTerms(),
        {
          select: data => {
            const responseData = data.data.data
            // const allPayments = responseData.data.map((payment) => ({
            //   id: payment.id,
            //   name: payment.name,
            //   months: payment.months,
            //   x_studio_field_2TA4r : payment.x_studio_field_2TA4r,
            // }))

            // filter dublicate data by months
            // const filteredPayments = allPayments.filter((payment,index) => {
            //   return allPayments.findIndex(obj => obj.months === payment.months) === index;
            // })
            

            setPaymentTerms(responseData)
            return responseData
          },
          ...options
        }
        )
}



const preFetchPaymentTerms = () => {
  return paymentTermsAPI()
}


const PaymentTermsService = {
  useFetchPaymentTerms,
  
};

export default PaymentTermsService;

