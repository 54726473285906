import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";

// COMPONENTS
import Rate from "components/UI/Rate/Rate";
import ReviewBox from "components/UI/ReviewBox/ReviewBox";
import DarkButton from "components/UI/DarkButton/DarkButton";
import TextAreaInput from "components/Form/Inputs/TextAreaInput/TextAreaInput";
import Spinner from "components/UI/Spinner/Spinner";

// STYLES
import styles from "./Reviews.module.scss";
import ProductRating from "features/Products/ProductRating";
import { useProfileStore } from "stores/useProfileStore";

const Reviews = ({rates , productId , subject , data , loadMore , onAddReview}) => {
	const [reviews , setReviews] = useState([])
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if(data){
			setReviews(data);
		}
	}, [data])

	// console.log(rates)
	// Reviews

	const {profile} = useProfileStore(state => state)
	const newReview = {
		message_id:{
			author_id :{
				name : profile.name,
			},
			subject : subject,
			date: new Date().toISOString(),
		},
	}

	// See All Handler
	const [heightAuto, setHeightAuto] = useState(false);
	const {mutate: addProductRating} = ProductRating.useAddProductRating();
	// Write a Review Form
	const formik = useFormik({
		initialValues: {
			review: "",
		},
		validate: (data) => {
			let errors = {};

			if (!data.review) {
				errors.review = "This field is required";
			}

			return errors;
		},
		onSubmit:  async (data) => {
			setIsLoading(true);
			setIsSubmitting(true);
			const review = {
				feedback: data.review, 
				rating_value: rating
			  }
			const params = {
				id:productId,
				review:review
			}
			try {
				await addProductRating(params);
				const addedReview = {
				  ...newReview,
				  message_id: {
					...newReview.message_id,
					body: `<p>${data.review}</p>`
				  },
				  rating: rating
				};
				setReviews([addedReview, ...reviews]);
				setIsLoading(false);
				onAddReview();
			  } catch (error) {
				console.error('Failed to add review:', error);
			  }
			setIsSubmitting(false);
			formik.resetForm();
		},
	});

	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);

	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	// Select Rate
	const [rating, setRating] = useState(1);

	return (
		<div className={styles.reviews_holder}>
			<div className="row">
				<div className="col-lg-3 mb-5 mb-lg-0">
					<div className={styles.verified_ratings}>
						{isLoading ? (<Spinner />) :(
							<>
						<div className={styles.col_tit}>VERIFIED RATINGS</div>
						<div className={styles.total_rates}>
							<div className={styles.total_rate}>
								<Rate rateVal={rates.avg} showVal readOnly={true} val={rates.avg.toFixed(1)} />
								<div className={styles.whoRate_num}>{rates.total} Rate</div>
							</div>
							<div className={styles.rates}>
								{Object.entries(rates?.percent)
								.filter(([key]) =>  parseInt(key) >= 0 && parseInt(key) <= 5)
								.reverse()
								.map(([key , value]) => (
									<div className={styles.rate_row} key={key}>
										<Rate
											rateVal={key}
											showVal
											readOnly={true}
											val={value.toFixed(2)}
											withPercantage
											rightPos
										/>
									</div>
								))}
							</div>
						</div>
						</>
						) }
					</div>
				</div>
				<div className="col-lg-9">
					<div className={styles.product_reviews}>
						<div className={styles.col_head}>
							<div className={styles.col_tit}>VERIFIED RATINGS</div>
							<Button
								label={!heightAuto ? "See All" : "Show Less"}
								aria-label="See All"
								className={styles.see_all}
								onClick={() => setHeightAuto(!heightAuto)}
							/>
						</div>

						{/* Reviews */}
						<div
							className={`${styles.reviews_rows} ${
								heightAuto ? styles.heightAuto : ""
							}`}
						>
							{reviews?.map((review,index) => (
								<ReviewBox reviewData={review} key={index} />
							))}
						</div>
						
						{/* <div className={styles.product_reviews}>
							<div className={styles.col_head}>
								<Button
									label={"Old Comments"}
									aria-label="See All"
									className={`${styles.see_all} ${styles.reviews_rows}`}
									onClick={loadMore}
									/>
								</div>
						</div> */}

						{/* Write a Review */}
						<div className={styles.write_review}>
							<div className={styles.write_review_tit}>Write a Review</div>
							<div className={styles.write_review_form}>
								<form onSubmit={formik.handleSubmit}>
									<div className={styles.form_head}>
										<div className={styles.select_rate}>
											<div className={styles.tit}>Select to rate</div>
											<Rate
												rateVal={rating}
												handleRateChange={(e) => {
													setRating(e.value);
												}}
												readOnly={false}
											/>
										</div>
										<div className={styles.form_btns}>
											<DarkButton btn type="button" label="Cancel" outlined />
											{isSubmitting ? (
												<Spinner />
											) : (
												<DarkButton btn type="submit" label="Submit" />
  											)}
										</div>
									</div>
									<div className={styles.form_body}>
										<TextAreaInput
											label="Your Review"
											name="review"
											value={formik.values.review}
											placeHolder="Type somthing"
											changeHandler={formik.handleChange}
											inputError={getFormErrorMessage("review")}
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Reviews;
