import { InputText } from "primereact/inputtext";
import { useState , useEffect ,useCallback } from "react";
import _ from 'lodash';

// STYLES
import styles from "./SearchInput.module.scss";
import { useProductsSearchValuesStore } from "stores/useSearchValuesStore";

export function SearchInput({ ...props }) {

	const updateSearchValue = useProductsSearchValuesStore(state => state.updateSearchValue);
	const searchInputValue = useProductsSearchValuesStore(state => state.searchInputValue);

	const [inputValue, setInputValue] = useState("");

	const debouncedUpdateSearchValue = useCallback(_.debounce(updateSearchValue, 500),[]);

	const handleChange = (e) => {
		const value = e.target.value;
		setInputValue(value);
		debouncedUpdateSearchValue(value);
	  };

	useEffect(() => {
		setInputValue(searchInputValue);
	}, [searchInputValue]);

	  
	useEffect(() => {
		return () => {
			debouncedUpdateSearchValue.cancel();
		};
	}, []);

	return (
		<div className={styles.search_input}>
			
			<span className="p-input-icon-right">
				<i className="pi pi-search" />
				<InputText value={inputValue} onChange={handleChange} placeholder={props.placeHolder} className="w-100" />
			</span>
		</div>
	);
}
