import axios from "lib/axios"


export const paymentTermsAPI = async () => {
    /* return [
        {
            "id": 1,
            "name": "immediate",
            "months": null,
            "x_studio_field_2TA4r": "(0)"
        },
        {
            "id": 112,
            "name": "الشراء بسعر المستهلك - السداد على 6 شهور بدون فائدة ",
            "months": 6,
            "x_studio_field_2TA4r": "0"
        }]*/ 
    return await axios.post(`/products/payment-terms`)
}
