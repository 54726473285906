import {create} from 'zustand'
export const usePaymentTerms = create((set , get)=>({
    paymentTerms : [],
    setPaymentTerms : (newPaymentTerms)=>{
        set((state) => {
            if (state.paymentTerms !== newPaymentTerms) {
              return { paymentTerms: newPaymentTerms };
            }
            return state;
          });
    }
}))