import * as yup from "yup";
import { PHONE_REGEX } from "./Regex";

export const signupValidationSchema = yup.object({
	fullName: yup.string()
	.min(4, "Please enter your real name")
	.max(25, "Please enter your real name")
	.required("Full name is required."),
	email: yup.string().email("Please enter a valid email address").required("Please enter your email address"),
	phoneNumber: yup.string().matches(PHONE_REGEX,"Please enter a valid phone number"),
	password: yup.string().required("Please enter your password"),
	confirmPassword: yup.string().required("Please confirm your password")
	.oneOf([yup.ref('password'), null], 'Passwords must match')
	});

export const loginValidationSchema = yup.object({
	email: yup.string().required("Email or Phone is required."),
	password: yup.string()
				.min(3,"Password must be less than 4 characters")
				.max(16,"Password can't be more than 16 characters")
				.required("Password is required."),
  });

