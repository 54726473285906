import { Link, Outlet } from "react-router-dom";

// Utils
import { LOGO } from "constants/Images";

// Lang Btn
import LanguageBtn from "layout/Header/components/LanguageBtn/LanguageBtn";

// STYLES
import styles from "./AuthLayout.module.scss";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

const AuthLayout = () => {
	return Cookies.get("front_session_id") ? (
		<Navigate to="/" />
	) : (
		<div className={styles.auth_layout}>
			<Link to="/" className={styles.app_logo}>
				<img src={LOGO} alt="" />
			</Link>
			{/* Lang Btn */}
			<LanguageBtn classNameProp={styles.auth_langBtn} />
			<Outlet />
		</div>
	);
};

export default AuthLayout;
