import { Galleria } from "primereact/galleria";

// STYLES
import styles from "./ProductCarousel.module.scss";

const ProductCarousel = (props) => {

  const mainImage = props.productDetails?.image;
  const additionalImages = props.productDetails?.product_image_ids;

  const productImages = additionalImages?.map((imgDetails) => ({
    itemImageSrc: imgDetails.image.url, 
    thumbnailImageSrc: imgDetails.image.url, 
    alt: `Product Image ${imgDetails.id}`, 
    title: `Product Image`, 
  }));

  const images = productImages?.length > 0
  ? [
      {
        itemImageSrc: mainImage,
        thumbnailImageSrc: mainImage,
        alt: "Main Product Image",
        title: "Main Product",
      },
      ...productImages,
    ]
  : [
      {
        itemImageSrc: mainImage,
        thumbnailImageSrc: mainImage,
        alt: "Main Product Image",
        title: "Main Product",
      },
    ];

  const responsiveOptions2 = [
    {
      breakpoint: "768px",
      numVisible: 4,
    },
    {
      breakpoint: "560px",
      numVisible: 4,
    },
    {
      breakpoint: "414px",
      numVisible: 4,
    },
  ];

  const itemTemplate = (item) => {
    return (
      <img
        src={item.itemImageSrc}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={item.alt}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.thumbnailImageSrc}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={item.alt}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  return (
    <div className={styles.product_carousel}>
      <Galleria
        value={images}
        responsiveOptions={responsiveOptions2}
        numVisible={4}
        thumbnailsPosition={window.innerWidth >= 768 ? "left" : "bottom"}
        style={{ maxWidth: "100%" }}
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
        showThumbnailNavigators={false}
      />
    </div>
  );
};

export default ProductCarousel;