// COMPONENTS
import DialogBox from "../DialogBox/DialogBox";
import Spinner from "../Spinner/Spinner";

// STYLES
import styles from "./DeleteDocumentModal.module.scss";

const DeleteDocumentModal = ({ isDeleting, showModal, hideModal, confirmDelete }) => {
	return (
	  <DialogBox
		header="Delete Document"
		displayDialog={showModal}
		hideModal={hideModal}
		buttonLabel="Confirm"
		onConfirm={confirmDelete}
	  >
		{isDeleting ? (
		  <Spinner />
		) : (
		  <div className={styles.delete_document_modal}>
			<img
			  src="/img/delete-msg-icon.svg"
			  alt=""
			  className={styles.delete_icon}
			/>
			<h5>Are you sure you want to delete this document?</h5>
			<p>Please make sure that you want to delete this document.</p>
		  </div>
		)}
	  </DialogBox>
	);
  };

export default DeleteDocumentModal;
