import axios from "lib/axios"

export const offerAPI = async (params) => {
    
    return await axios.post("/offers", params)
}

export const offerDetailsAPI = async (id) => {

    return await axios.post(`/offers/${id}`, {})
}

export const getOfferAPI = async (params) => {

    return await axios.post(`/apply-offer`, params)
}

// /api/offers/categories