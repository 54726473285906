import { useQuery,useMutation } from "react-query";
import { offerAPI, offerDetailsAPI, getOfferAPI} from "api/offer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useOffersSearchValuesStore } from "stores/useSearchValuesStore";

// This is a custom hook that uses useMutation to fetch Offer data and return it in response. 
// This hook is then used in Offer component to sign the user in using the signIn function from useSignIn hook. 
// It also navigates the user to the homepage and shows a toast message.

const useFetchOffers = (data,options) =>  {
    
    return useQuery(['offers',data],
      () => preFetchOffer(data),
        {
          select: data => {
            const responseData = data.data
            // const allOffers = responseData.data.map((offer) => ({
            // }))
            // debugger
            // setPaginationConfig(responseData)
            return responseData.data
          }
          ,
            ...options
        }
        )
}

const useFetchOfferDetails = (id,options) =>  {
    
  // const signIn = useSignIn();
  // const navigate = useNavigate();
  // const setPaginationConfig = useOffersSearchValuesStore( state => state.setPaginationConfig)

  return useQuery(['offers-details',id],
  () => preFetchOfferDetails(id),
      {
        select: data => {
          const responseData = data.data
          // const allOffers = responseData.data.map((offer) => ({
  
          // debugger
          // setPaginationConfig(responseData)
          return responseData
        },
          ...options
      }
      )
}

const useGetOffer = (options) =>  {
  // const queryClient = useQueryClient();
  
  return useMutation(preGetOffer,
      {
        select: data => {
          const responseData = data.data
          return responseData
        },
          ...options
      }
      )
}

// This function takes in parameters and passes them to a Offer API function
// The function is named preFetchOffer
// The function takes in a parameter called params
// The function returns the output of the OfferAPI function

const preFetchOffer = (data) => {
  return offerAPI(data)
}
const preFetchOfferDetails = (id) => {
  return offerDetailsAPI(id)
}
const preGetOffer = (data) => {
  // debugger
  return getOfferAPI(data)
}

const OfferService = {
  useFetchOffers,
  useFetchOfferDetails,
  useGetOffer
};

export default OfferService;

