import { useQuery,useMutation,useQueryClient } from "react-query";
import { profileAPI,partnersAPI,updateProfileAPI} from "api/partner";
import { useProfileStore } from "stores/useProfileStore";
import { getUserDocuments } from "api/partner";
import { uploadUserDocuments } from "api/partner";
import { deleteUserDocuments  } from "api/partner";


const useFetchProfile = (options) =>  {
  const updateProfile = useProfileStore(state => state.updateProfile)
    return useQuery(['profile'],
      () => preFetchProfile(),
        {
          select: data => {
            const responseData = data.data
            updateProfile(responseData)
            return responseData
          }
          ,
            ...options
        }
        )
}
const useUpdateProfile = (options) => {
  const queryClient = useQueryClient();

  return useMutation(preUpdateProfile, {
    ...options,
    onSuccess: async (data) => {
      
      // Invalidate queries to refetch the updated profile
      await queryClient.invalidateQueries('profile');
      
      // Refetch the profile data
      await queryClient.refetchQueries('profile');
    },
    onError: (error) => {
    },
  });
};


const useFetchPartners = (data,options) =>  {

  return useQuery(['partners'],
    () => preFetchPartners(data),
      {
        select: data => {
          const responseData = data.data
          return responseData.data
        }
        ,
          ...options
      }
      )
}


const preFetchProfile = () => {

  return profileAPI({})
}

const preUpdateProfile = (params) => {
  const data = {
    name: params.fullName,
    email: params.email,
    phone: params.phoneNumber,
    password: params.password,
  };

  // Only add image key if image is present and is not undefined
  if (params?.image) {
    data.image = params.image.split('base64,')[1];
  }

  return updateProfileAPI(data);
};


const preFetchPartners = (data) => {

  return partnersAPI({...data,'supplier_rank':1})
}


export const useFetchUserDocuments = (options) => {
  const queryClient = useQueryClient();

  return useQuery(['userDocuments'], async () => {
    const response = await getUserDocuments({});
    return response.data;
  }, {
    ...options,
    onSuccess: (data) => {
      queryClient.setQueryData(['userDocuments'], data);
    },
  });
};

const useUploadUserDocument = () => {
  const queryClient = useQueryClient();

  return useMutation(uploadUserDocuments, {
    onSuccess: (data) => {
      const documentDetails = data.data;

      queryClient.setQueryData('userDocuments', (oldDocuments) => {
        const documentsArray = Array.isArray(oldDocuments) ? oldDocuments : [];
        return [...documentsArray, documentDetails];
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
};

const useDeleteUserDocument = () => {
  const queryClient = useQueryClient();

  return useMutation((documentName) => deleteUserDocuments({document_name : documentName}), {
    onSuccess: () => {
      queryClient.invalidateQueries('userDocuments');
    },
    onError: (error) => {
      console.error('Error deleting the document:', error);
    },
  });
};

const PartnerService = {
  useFetchProfile,
  useFetchPartners,
  useUpdateProfile,
  useFetchUserDocuments,
  useUploadUserDocument,
  useDeleteUserDocument
};


export default PartnerService;

