import { Outlet } from "react-router-dom";
// COMPONENTS
import Footer from "layout/Footer/Footer";
import Header from "layout/Header/Header";
// STYLES
import styles from "./MainLayout.module.scss";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import PartnerService from "features/Partners/PartnerService";


const MainLayout = () => {
	const auth = Cookies.get('front_session_id');
	const {isLoading, isFetching, data:profile } = PartnerService.useFetchProfile()
	
	// debugger
	return (
		Cookies.get('front_session_id') ? <div className={styles.main_layout}>
			<Header userName={profile?.name}/>
			<div className={styles.outlet_content}>
				<Outlet />
			</div>
			<Footer />
		</div> : <Navigate to="/login" />
	);
};

export default MainLayout;
