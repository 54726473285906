// COMPONENTS
import Rank from "components/UI/Rank/Rank";
import CustomRadioBtn from "components/Form/Inputs/CustomRadioBtn/CustomRadioBtn";
import GradientButton from "components/UI/GradientButton/GradientButton";
// import LikeButton from "components/UI/LikeButton/LikeButton";
// import CartButton from "components/UI/CartButton/CartButton";

// STYLES
import styles from "./ProductInfo.module.scss";
import { useEffect, useState } from "react";
import { usePaymentTerms } from "stores/usePaymentTerms";
import ProductService from "features/Products/ProductService";

const filterInstallmentsByMonth = (installments) => {
	let Months = []
	let data = []
	installments.map(installment => {
		if(!Months.includes(installment.months) && installment.months != null){
			Months.push(installment.months)
			data.push(installment)
		}
	})

	data.sort((a,b) => a.months - b.months)
	return data
}

const ProductInfo = ({productDetails , setDescription}) => {
	const {paymentTerms} = usePaymentTerms()
	const [lineIdsActive , setLineIdsActive] = useState(0)
	const [installmentsActive , SetInstallmentsActive] = useState(0)
	const [installments , SetInstallments] = useState([])
	const [paymentData , setPaymentData] = useState({});

	const {data : productDetailsCustomize} = ProductService.useFetchProductWithPayment(productDetails.id, paymentData);

	useEffect(() => {
		SetInstallments(filterInstallmentsByMonth(paymentTerms))
	}, [paymentTerms])

	useEffect(() => {
		if (installments.length > 0) {
		  setPaymentData({
			values: productDetails.first_combination,
			payment_term_id: installments[0]?.id,
		  });
		setDescription(productDetailsCustomize?.website_description)
		}
	  }, [installments, productDetails]);

	const installmentsAction =  (id)=>{
		const data = {
			values : productDetails.first_combination,
			payment_term_id : +id
		}
		setPaymentData(data)
		setDescription(productDetailsCustomize?.website_description)
	}
	const ActivateInstallment = (installmentsActive)=>{
		SetInstallmentsActive(installmentsActive)
	}
	const ActivateLineIds = (lineIdsActive)=>{
		setLineIdsActive(lineIdsActive)
	}
	// console.log(productDetails)
	return (
		<div className={styles.product_info}>
			<div className={styles.product_info_row}>
				<div className={styles.model_rate}>
					<div className={styles.model}>Model: OLED42C2PSA</div>
					<Rank rankVal={productDetails.rating.total} />
				</div>
				<div className={styles.availability}>
					<div className={styles.availability_txt}>Availability:</div>
					<div className={styles.availability_val}>
						only {productDetailsCustomize?.qty_available ? productDetailsCustomize?.qty_available : 0 } avilable in stock
					</div>
				</div>
				<h2>{productDetailsCustomize?.name}</h2>
				<ul className={styles.details_list}>
					<li>α9 Gen5 AI Processor with AI Picture Pro & AI 4K Upscaling</li>
					<li>
						Pixel Dimming, Perfect Black, 100% Color Fidelity & Color Volume
					</li>
					<li>Hands-free Voice Control, Always Ready</li>
					<li>
						Dolby Vision IQ with Precision Detail, Dolby Atmos, Filmmaker Mode
					</li>
					<li>Eye Comfort Display: Low-Blue Light, Flicker-Free</li>
				</ul>
			</div>

			{/* Size */}
			<div className={styles.product_info_row}>
				{/* {
					productDetails?.attribute_line_ids.map( (attr) => (
				<>
				 <div className={styles.sec_tit}>{attr.name}</div>
					<div className={styles.sizes}>
						{attr.value_ids.map((value, index) => (
							<CustomRadioBtn
								name={attr.name}
								label={value.name}
								value={value.id}
								key={index}
								ActivateLineIds={ActivateLineIds}
								active={lineIdsActive === index ? true : false}
							/>
						))}
					</div>
				</>
					) )
				} */}
				{/* TODO */}
				{/* <div className={styles.sec_tit}>{productDetails?.attribute_line_ids[0]?.attribute_id.name}</div> */}
				{/* <div className={styles.sec_tit}>Size</div>
				<div className={styles.sizes}>
					{sizes.map((size, index) => (
						<CustomRadioBtn
							name="size"
							label={size.label}
							value={size.value}
							key={index}
						/>
					))}
				</div> */}
			</div>

			{/* Price & Installments */}
			<div className={styles.product_info_row}>
				{/* Total Price & Per Installment */}
				<div className={styles.price_installment}>
					<div className="row">
						<div className="col-md-6">
							<div className={styles.price_installment_box}>
								<div className={styles.tit}>Total price:</div>
								<div className={styles.price_holder}>
									<div className={styles.price}>{productDetailsCustomize?.website_price} EGP</div>
									{/* <div className={styles.afterDiscount}>${productDetails.list_price}</div> */}
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className={styles.price_installment_box}>
								<div className={styles.tit}>Per instalment:</div>
								<div className={styles.price_holder}>
									<div className={styles.price}>{productDetailsCustomize?.installment_value} EGP</div>
									{/* <div className={styles.afterDiscount}>80.00 EGP</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Installmets */}
				{/* TODO */}
				<div className={styles.installments}>
					<div className={styles.installments_tit}>Installments</div>
					<div className={styles.installments_boxes}>
						{installments.map((installment, index) => (
							<CustomRadioBtn
								name="installments"
								label={installment?.months}
								value={installment?.months}
								id = {installment.id}
								getId = {installmentsAction}
								key={index}
								index={index}
								active={installmentsActive === index ? true : false}
								SetInstallmentsActive = {ActivateInstallment}
								installmentLbl
							/>
						))}
					</div>
				</div>

				{/* Buttons */}
				<div className={styles.btns_holder}>
					{/* Buy Now Btn */}
					<GradientButton label="Buy Now" btn />

					{/* Like Btn */}
					{/* <LikeButton /> */}

					{/* Cart Btn */}
					{/* <CartButton /> */}
				</div>
			</div>
		</div>
	);
};

export default ProductInfo;
