import axios from "lib/axios"

export const loginAPI = async (params) => {
    // return {"front_session_id": "1234567890", "user_name": "test"}
    return await axios.post("/login", params)
}

export const signupAPI = async (params) => {
    // return {"front_session_id": "1234567890", "user_name": "test"}
    return await axios.post(`/signup`, params)
}