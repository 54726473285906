import { useMutation } from "react-query";
import { loginAPI } from "api/auth";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from 'js-cookie'

// This is a custom hook that uses useMutation to fetch login data and return it in response. 
// This hook is then used in Login component to sign the user in using the signIn function from useSignIn hook. 
// It also navigates the user to the homepage and shows a toast message.

const useFetchLogin = (options) =>  {
    
    // const signIn = useSignIn();
    const navigate = useNavigate();

    return useMutation(
      preFetchLogin,{
            onSuccess: (response) => {
                // signIn({
                //   token: response?.data?.front_session_id,
                //   expiresIn: 3600,
                //   authState: { user: response?.data?.user_name }, 
                // });
                Cookies.set('front_session_id', response?.data?.session_id, { expires: 3600 })
                localStorage.setItem('user_name', response?.data?.user_name)
                localStorage.setItem('image', response?.data?.image)
                // debugger
                toast.success("Welcome again to BanKey!")
                navigate("/shop/products");
                },
            ...options
        }
        )
}

// This function takes in parameters and passes them to a login API function
// The function is named preFetchLogin
// The function takes in a parameter called params
// The function returns the output of the loginAPI function

const preFetchLogin = (params) => {
  const data  = {
    login: params.email,
    password: params.password,
  }
  return loginAPI(data)
}

const LoginService = {
  useFetchLogin 
};

export default LoginService;

