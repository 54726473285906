// COMPONENTS
import BreadCrumbComp from "components/UI/BreadCrumbComp/BreadCrumbComp";
import PageTitle from "components/UI/PageTitle/PageTitle";
import Spinner from "components/UI/Spinner/Spinner";

// Child Components
import ProductCarousel from "./components/ProductCarousel/ProductCarousel";
import ProductInfo from "./components/ProductInfo/ProductInfo";
import DescReviews from "./components/DescReviews/DescReviews";
import RelatedProducts from "./components/RelatedProducts/RelatedProducts";
import ProductService from "features/Products/ProductService";

// STYLES
import styles from "./ProductDetails.module.scss";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useProductsSearchValuesStore } from "stores/useSearchValuesStore";


function shuffleArray(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex !== 0) {

        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

const ProductDetails = () => {
	// Bread Crumb
	const items = [
		{ label: "Home" },
		{ label: "Products" },
	];

	const { id } = useParams()

	const [description,setDescription] = useState('')	

	const {isLoading, isFetching, data:productDetails , refetch } = ProductService.useFetchProductDetails(id)
	const [rates , setRates ] = useState(productDetails?.rating);

	const refetchRating = async() => {
		await refetch();
	}

	const categoryIds = productDetails?.public_categ_ids?.map(categ => categ.id);
	
	const filters = useProductsSearchValuesStore(state => state.searchFilters);

	const relatedProductFilters = {
		...filters, 
		categories: categoryIds, 
	  };

	
	const {  data: allProducts } = ProductService.useFetchProduct('', relatedProductFilters, { min : null, max : null });
	const shuffledProducts = allProducts ? shuffleArray([...allProducts]) : [];
	const relatedProductsToDisplay = allProducts ? shuffledProducts.slice(0, 5) : [];
	
	useEffect(()=>{
		setRates(productDetails?.rating)
	},[productDetails]);

	if (isLoading ) {
		return <Spinner />;
	}
	
	return (
		<div className={styles.product_details}>
			<div className="container">
				{/* Bread Crumb */}
				<BreadCrumbComp items={items} />

				{/* Page Title */}
				<PageTitle title={productDetails ? productDetails.name : "Loading..."} />

				{/* Top Details */}
				<div className={styles.pro_details_top}>
					{/* Product Data */}
					<div className={styles.carousel_info}>
						<div className="row">
							{/* Product Images */}
							<div className="col-xl-6 mb-5 mb-xl-0">
								<ProductCarousel productDetails={productDetails}/>
							</div>

							{/* Product Info */}
							<div className="col-xl-6">
							{productDetails ? <ProductInfo productDetails={productDetails} setDescription={setDescription} />  : 'Loading...'}
							</div>
						</div>
					</div>

					{/* Description & Reviews */}
					{productDetails ? <DescReviews refetchRating={refetchRating} subject={productDetails.name} rating={rates} productId = {productDetails.id} description={productDetails.bankkey_description}/> : 'Loading...'}				</div>

				{/* Related Products */}
				<RelatedProducts products={relatedProductsToDisplay}/>
			</div>
		</div>
	);
};

export default ProductDetails;
