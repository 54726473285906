import create from "zustand";

export const useProductsSearchValuesStore = create((set, get) => ({
	searchValue: "",
	searchInputValue: "",
	searchFilters: {
		categories: [],
		priceFilter: { min: null, max: null },
	},
	numberOfPages: 0,
	numberOfProducts: 0,
	pageNumber : 1,
	userPriceFilterSet: false,
	setPageNumber: (newPageNumber) => {
		set({ pageNumber: newPageNumber });
	},
	updateSearchValue: (newSearchValue) => {
		set({ searchValue: newSearchValue });
		set({ searchInputValue: newSearchValue });
	},
	setPaginationConfig: (config) => {
		set({
			numberOfPages: config?.number_of_pages,
			numberOfProducts: config?.number_of_items,
			min_price : config?.min_price,
			max_price : config?.max_price
		});
	},
	setCategoriesFields: (newCategories) => {
		set((state) => ({
			searchFilters: {
				...state.searchFilters, 
				categories: [...newCategories.map((categ) => categ.key)],
			},
		}));
	},
	setPriceFilter: (minPrice, maxPrice) => {
		set(state => ({
		  userPriceFilterSet: true,
		  searchFilters: {
			...state.searchFilters,
			priceFilter: { min: minPrice, max: maxPrice },
		  },
		}));
	  },
	  resetPriceFilter: () => {
		set(state => ({
		  userPriceFilterSet: false,
		  searchFilters: {
			...state.searchFilters,
			priceFilter: { min: null, max: null },
		  },
		}));
	  },
	  resetSearchInputValue: () => {
        set({ searchInputValue: "" }); 
    },
}));
