import { useState , useEffect} from "react";

// Child Components
import DocumentBox from "./components/DocumentBox/DocumentBox";

// STYLES
import styles from "./PersonalDocuments.module.scss";

const PersonalDocuments = ({userDocuments, onUserDocumentsUpdate}) => {
	const [initialUserDocuments, setInitialUserDocuments] = useState([]);

	const bytesToMB = (bytes) => {
		return (bytes / (1024 * 1024)).toFixed(2);
	  };

	useEffect(()=>{

		 const documents = [
			{
			  id: 1,
			  image: userDocuments?.id_front_document?.file?.url || "/img/imagePlaceholder.webp",
			  name: "National ID front",
			  type: "id_front_document",
			  size: userDocuments?.id_front_document ? bytesToMB(userDocuments?.id_front_document?.file?.file_size) + "MB" : undefined, 
			  status: userDocuments?.id_front_document ? userDocuments?.id_front_document?.status : "No Document Yet",
			  mimetype : userDocuments?.id_front_document ? userDocuments?.id_front_document?.file?.mimetype : null,
			},
			{
			  id: 2,
			  image: userDocuments?.id_back_document?.file?.url || "/img/imagePlaceholder.webp",
			  name: "National ID back",
			  type: "id_back_document",
			  size: userDocuments?.id_back_document ? bytesToMB(userDocuments?.id_back_document?.file?.file_size) + "MB" : undefined,
			  status: userDocuments?.id_back_document ? userDocuments?.id_back_document?.status : "No Document Yet",
			  mimetype : userDocuments?.id_back_document ? userDocuments?.id_back_document?.file?.mimetype : null,
			},
			{
			  id: 3,
			  image: userDocuments?.hr_letter_document?.file?.url || "/img/imagePlaceholder.webp",
			  name: "HR Letter",
			  type: "hr_letter_document",
			  size: userDocuments?.hr_letter_document ? bytesToMB(userDocuments?.hr_letter_document?.file?.file_size) + "MB" : undefined,
			  status: userDocuments?.hr_letter_document ? userDocuments?.hr_letter_document?.status : "No Document Yet",
			  mimetype : userDocuments?.hr_letter_document ? userDocuments?.hr_letter_document?.file?.mimetype : null,
			},
			{
			  id: 4,
			  image: userDocuments?.card_license_document?.file?.url || "/img/imagePlaceholder.webp",
			  name: "Card License",
			  type: "card_license_document",
			  size: userDocuments?.card_license_document ? bytesToMB(userDocuments?.card_license_document?.file?.file_size) + "MB" : undefined,
			  status: userDocuments?.card_license_document ? userDocuments?.card_license_document?.status : "No Document Yet",
			  mimetype : userDocuments?.card_license_document ? userDocuments?.card_license_document?.file?.mimetype : null,
			},
		  ];
		  setInitialUserDocuments(documents);
	},[userDocuments])

	const handleDocumentUpdate = (updatedData) => {
	
		const updatedDocuments = [...initialUserDocuments];
	
		Object.keys(updatedData).forEach((docType) => {
			const updatedDoc = updatedData[docType];
			
			const index = updatedDocuments.findIndex((doc) => doc.type === docType);
	
			if (index !== -1) {
				updatedDocuments[index] = {
					...updatedDocuments[index],
					image: updatedDoc.file.url,
					status: updatedDoc.status,
				};
			}
		});
	
		setInitialUserDocuments(updatedDocuments);
		onUserDocumentsUpdate();
	};

	  return (
		<div className={styles.personal_documents}>
		  <h2>Personal Documents</h2>
		  <div className={styles.documents}>
			{initialUserDocuments.map((document) => (
			  <DocumentBox
				document={document}
				key={document.id}
				canDelete={!!document.size} 
				onDocumentUpdate={handleDocumentUpdate}
			  />
			))}
		  </div>
		</div>
	  );
	};

export default PersonalDocuments;
