import { Button } from "primereact/button";
import { useTranslation, withTranslation } from "react-i18next";

// STYLES
import styles from "./LanguageBtn.module.scss";

const LanguageBtn = ({ ...props }) => {
	const { i18n } = useTranslation();
	const currLang = i18n.language;
	document.body.dir = i18n.dir();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		document.body.dir = i18n.dir();
		// window.location.reload();
	};

	return (
		<Button
			label={currLang === "ar" ? "EN" : "عربي"}
			className={`${styles.lang_btn} ${props.classNameProp}`}
			onClick={() => changeLanguage(currLang === "ar" ? "en" : "ar")}
		/>
	);
};

export default withTranslation()(LanguageBtn);
