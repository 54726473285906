import PartnerService  from "features/Partners/PartnerService";
import { useEffect, useState } from "react";

// Utils
import { editYellow, deleteYellow } from "constants/Images";
import { Button } from "primereact/button";
import { toast } from "react-hot-toast";

// COMPONENTS
import Status from "components/UI/Status/Status";
import DeleteDocumentModal from "components/UI/DeleteDocumentModal/DeleteDocumentModal";
import Spinner from "components/UI/Spinner/Spinner";

// STYLES
import styles from "./DocumentBox.module.scss";


const DocumentBox = ({ document , onDocumentUpdate}) => {
    const [deleteModal, setDeleteModal] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState(document.image || null);
	
	const { mutate: uploadDocument } = PartnerService.useUploadUserDocument();
	const { mutate : deleteDocument } = PartnerService.useDeleteUserDocument();

		useEffect(()=>{
				if(document?.mimetype?.includes("image")){
					setImage(document.image || null);
				}else if(document?.mimetype?.includes("pdf")){
					setImage("/img/pdf.svg");
				}else{
					setImage("/img/imagePlaceholder.webp");
				}
		},[document])


    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
			setIsLoading(true);
            let file = event.target.files[0];
			const fileType = file.type;

			if (!fileType.includes("image") && !fileType.includes("pdf")) {
				toast.error("Only image and PDF files are allowed.");
				setIsLoading(false);
				return; 
			}

            let previewImage;

			if (fileType.includes("image")) {
				previewImage = URL.createObjectURL(file);
			} else {
				previewImage = "/img/pdf.svg";
			}

            setImage(previewImage);

            const formData = new FormData();
            formData.append(document.type, file);
			
            uploadDocument(formData, {
				onSuccess: (data) => {
				
					const updatedDocumentType = Object.keys(data.data)[0];
					const updatedDocument = data.data[updatedDocumentType];
				
					onDocumentUpdate({ [updatedDocumentType]: updatedDocument });
					setIsLoading(false);
				},
				
				onError: (error) => {
					console.log(error);
					setIsLoading(false);
				},
			  });
        }
    };

	const confirmDelete = () => {
		setIsLoading(true);
		setIsDeleting(true);
		deleteDocument(document.type, {
			onSuccess: () => {
				setDeleteModal(false);
				setIsLoading(false);
				setIsDeleting(false);
			},
			onError: (error) => {
				console.log(error);
				setIsLoading(false);
				setIsDeleting(false);
			}
		});
	};

    return (
        <>
            <div className={styles.document_box}>
                <label className={styles.doc_img}>
                    <input type="file" name="docImg" onChange={onImageChange} />
                    <div className={styles.doc_img_box}>
					{isLoading ? (
                          <Spinner /> 
                        ) : (
							<img key={document.id} src={image} alt="" className={styles.img} />
                        )}
                    </div>
                    {
                        <div className={styles.plus_icon}>
                            <i className="pi pi-plus"></i>
                        </div>
                    }
                </label>
                <div className={styles.doc_info}>
                    <div className={styles.doc_name}>{document.name}</div>
                    <div className={styles.size_status}>
                        <div className={styles.size}>Size : {document.size || ''}</div>
                        <Status status={document.status || 'No Document Yet'} />
                    </div>
                    <div className={styles.doc_btns}>
					{document.status !== 'No Document Yet' && (
						<Button
							aria-label="Delete"
							className={styles.doc_btn}
							onClick={() => setDeleteModal(true)}
						>
							<img src={deleteYellow} alt="" />
						</Button>
					)}
                    </div>
                </div>
            </div>

            {/* Delete Document Modal */}
					<DeleteDocumentModal
						showModal={deleteModal}
						hideModal={() => setDeleteModal(false)}
						confirmDelete={confirmDelete}
						isDeleting={isDeleting} 
					/>
        </>
    );
};

export default DocumentBox;
