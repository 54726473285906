import { useState } from "react";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

// COMPONENTS
import DialogBox from "components/UI/DialogBox/DialogBox";
import { PasswordInput, TextInput } from "components/Form/Inputs";
import PartnerService from "features/Partners/PartnerService";
import DarkButton from "components/UI/DarkButton/DarkButton";
import Spinner from "components/UI/Spinner/Spinner";

// STYLES
import styles from "./EditUserInfo.module.scss";

const EditUserInfo = ({ ...props }) => {
	const navigate = useNavigate();
	const { isLoading, mutateAsync } = PartnerService.useUpdateProfile();

	const onSubmit = async (values) => {
		if (imageFile) {
		  values = { ...values, image: imageFile };
		}
	  
		try {
		  const updatedProfile = await mutateAsync(values);
		  
		  formik.resetForm();
		  props.hideModal();
		  props.onUpdateProfile(updatedProfile);
		  if (values.password) {
			toast.error("Session expired, please login again.");
			sessionStorage.clear();
			localStorage.clear();
			Cookies.remove('front_session_id');
        	Cookies.remove('session_id');
			navigate('/login');
		}
		} catch (error) {
		  console.error("Error occurred during mutation:", error);
		}
	  };
	  
	  
	  const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
		  fullName: props.profile?.name,
		  phoneNumber: props.profile?.phone,
		  email: props.profile?.email,
		  password: "",
		  confirmPassword: "",
		},
		validate: (values) => {
		  let errors = {};
	  
		  // Full Name validation
		  if (values.fullName !== props.profile?.name && !values.fullName) {
			errors.fullName = "Full name is required.";
		  }
	  
		  // Phone Number validation
		  if (values.phoneNumber !== props.profile?.phone) {
			if (!values.phoneNumber) {
			  errors.phoneNumber = "Phone number is required.";
			} else if (!/^[0-9]{9,15}$/g.test(values.phoneNumber)) {
			  errors.phoneNumber = "Invalid phone number";
			}
		  }
	  
		  // Email validation
		  if (values.email !== props.profile?.email) {
			if (!values.email) {
			  errors.email = "Email is required.";
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			  errors.email = "Invalid email address. E.g. example@email.com";
			}
		  }
	  
		  // Password validation
		  if (values.password && !/^(?=.*[a-z])(?=.*\d)(?=.*[A-Z]).{8,}$/.test(values.password)) {
			errors.password = "Password must be at least 8 characters long and include at least one lowercase letter, one number, and one uppercase letter.";
		}
	  
		  // Confirm Password validation
		  if (values.password && values.confirmPassword !== values.password) {
			errors.confirmPassword = "Passwords must match.";
		  }
	  
		  return errors;
		},
		onSubmit,
	  });

	
	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);

	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	// Handle Image Change
	const [image, setImage] = useState(null);
	const [imageFile, setImageFile] = useState(null);
	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setImage(URL.createObjectURL(img));
		}
		// send image to server as base64
		let reader = new FileReader();
		reader.onload = function (e) {
			// e.target.result;
			setImageFile(e.target.result);
		};
		reader.readAsDataURL(event.target.files[0]);
		
	};

	return (
		<DialogBox
			header="Edit User"
			displayDialog={props.showModal}
			hideModal={props.hideModal}
			hidebtns="true"
			className={styles.edit_dialog}
		>
			{isLoading ? <Spinner /> : 
			

			<form className={styles.edit_user} onSubmit={formik.handleSubmit}>
				<div className={styles.from_inputs}>
					<div className="row">
						<div className="col-md-12 mb-4">
							<label className={styles.upload_img}>
								<input type="file" name="userImg" onChange={onImageChange} />
								<div className={styles.img_holder}>
									<img src={image ? image : props.profile.image+"&session_id=" + Cookies.get("front_session_id")} alt="" />
								</div>
								<div className={styles.cam_icon}>
									<img src="/img/camera.png" alt="" />
								</div>
							</label>
						</div>
						<div className="col-md-6 mb-4">
							<TextInput
								inputLabel="Full name"
								inputName="fullName"
								placeHolder="Type your full name"
								inputVal={formik.values.fullName}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("fullName")}
								handleBlur={formik.handleBlur}
							/>
						</div>
						<div className="col-md-6 mb-4">
							<TextInput
								inputLabel="E-mail"
								inputName="email"
								placeHolder="Type your e-mail"
								inputVal={formik.values.email}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("email")}
								handleBlur={formik.handleBlur}
							/>
						</div>
						<div className="col-md-6 mb-4">
							<TextInput
								inputLabel="Phone number"
								inputName="phoneNumber"
								placeHolder="Type your phone number"
								inputVal={formik.values.phoneNumber}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("phoneNumber")}
								handleBlur={formik.handleBlur}
							/>
						</div>
						<div className="col-md-6 mb-4">
							<PasswordInput
								inputLabel="Password"
								inputName="password"
								placeHolder="Type your pasword"
								inputVal={formik.values.password}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("password")}
								handleBlur={formik.handleBlur}
							/>
						</div>
						<div className="col-md-6 mb-4">
							<PasswordInput
								inputLabel="Confirm New Password"
								inputName="confirmPassword"
								placeHolder="Type confirm new password"
								inputVal={formik.values.confirmPassword}
								changeHandler={formik.handleChange}
								inputError={getFormErrorMessage("confirmPassword")}
								handleBlur={formik.handleBlur}
							/>
						</div>
					</div>
				</div>
				<div className={styles.form_btns}>
					<DarkButton
						btn
						type="button"
						label="Cancel"
						outlined
						
						buttonClicked={props.hideModal}
					/>
					<DarkButton btn type="submit" label="Save"
						loading={isLoading}
						disabled={!formik.isValid}
						// buttonClicked={props.hideModal}
					/>
				</div>
			</form>
			}
		</DialogBox>
	);
};

export default EditUserInfo;
