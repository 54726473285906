import { TabView, TabPanel } from "primereact/tabview";

// COMPONENTS
import BreadCrumbComp from "components/UI/BreadCrumbComp/BreadCrumbComp";
import PageTitle from "components/UI/PageTitle/PageTitle";

// Child Components
import ProfileIntro from "./components/ProfileIntro/ProfileIntro";
import PersonalDocuments from "./components/PersonalDocuments/PersonalDocuments";
import MyPurchase from "./components/MyPurchase/MyPurchase";
import MyOffers from "./components/MyOffers/MyOffers";

// STYLES
import styles from "./Profile.module.scss";

import InstallmentService from "features/Installments/InstallmentService";
import PartnerService, { useFetchUserDocuments } from "features/Partners/PartnerService";
import { useProfileStore } from "stores/useProfileStore";

const Profile = () => {
	// Bread Crumb
	const items = [{ label: "Home" }, { label: "My Profile" }];
	// const {isLoading, isFetching, data:profile } = PartnerService.useFetchProfile()
	const profile = useProfileStore((state) => state.profile);

	const { data: userDocuments, isLoading, isError, error , refetch } = useFetchUserDocuments();

	const onUserDocumentsUpdate = () => {
			refetch();
	  };

	// const {
	// 	isLoading: installmentIsLoading,
	// 	isFetching: installmentIsFetching,
	// 	data: installments,
	// } = InstallmentService.useFetchInstallment(
	// 	{ page: 1, partner_id: profile?.id },
	// 	{ enabled: !!profile?.id }
	// );

	return (
		<div className={styles.profile}>
			<div className="container">
				{/* Bread Crumb */}
				<BreadCrumbComp items={items} />

				{/* Page Title */}
				<PageTitle title="My Profile" />

				{/* Profile Intro */}
				<ProfileIntro profile={profile} />

				{/* Profile Body */}
				<div className={styles.profile_body}>
					<div className="row">
						<div className="col-lg-4 mb-5 mb-lg-0">
							<PersonalDocuments userDocuments={userDocuments} onUserDocumentsUpdate={onUserDocumentsUpdate} />
						</div>
						<div className="col-lg-8">
							<div className={styles.purchase_offers}>
								<TabView>
									{/* <TabPanel header="My purchase">
										<MyPurchase installments={installments} />
									</TabPanel> */}
									<TabPanel header="My offers">
										<MyOffers />
									</TabPanel>
								</TabView>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;
