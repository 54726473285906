import { addProductRatingApi, getProductRating } from "api/rating";
import { useMutation, useQuery , useQueryClient } from "react-query";


const addProductRating = (params) => {
  const payload = {
    ...params.review,
    feedback: params.review.feedback 
  };

  return addProductRatingApi(params.id, payload);
};

const useAddProductRating = (options) => {
  const queryClient = useQueryClient();
  return useMutation(addProductRating, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('products-rating');
      queryClient.invalidateQueries('products-details');
    },
    onError: (error) => {
      console.log(error)
    },
    ...options,
  });
};


const useFetchProductRating = (id, pageNumber ,options) =>  {
  return useQuery(['products-rating',id,pageNumber],
    () => preFetchProductRating(id, {
        "page": pageNumber ? pageNumber : 1
    }),
      {
        select: data => {
          const responseData = data.data.data
          return responseData
        }
        ,
          ...options
      }
      )
}


const preFetchProductRating = (id, data )=>{
    return getProductRating(id, data )
}


const ProductRating = {
  useFetchProductRating,
  useAddProductRating
};

export default ProductRating;

