import Cookies from "js-cookie";
import React , {useState, useEffect} from "react";

// COMPONENTS
import Rate from "../Rate/Rate";
import Spinner from "../Spinner/Spinner";

// STYLES
import styles from "./ReviewBox.module.scss";

const ReviewBox = ({ reviewData }) => {

	const [imageLoaded, setImageLoaded] = useState(false);
	const [image , setImage ] = useState(null);

	useEffect(() => {
		if (reviewData?.partner_id?.image) {
		  const img = new Image();
		  const imageUrl = `${reviewData.partner_id.image}&session_id=${Cookies.get('front_session_id')}`;
		  img.src = imageUrl;
	
		  const handleImageLoad = () => {
			setImage(imageUrl);
			setImageLoaded(true);
		  };
	
		  img.onload = handleImageLoad;
	
		  if (img.complete) {
			handleImageLoad();
		  }
		  return () => {
			img.onload = null;
		  };
		} else {
		  setImage(null);
		  setImageLoaded(false);
		}
	  }, [reviewData?.partner_id?.image]);

	const calcDuration = () => {
		try {
			const reviewDate = new Date(reviewData?.create_date);
			const now = new Date();
			
			if (isNaN(reviewDate.getTime()) || reviewDate > now) {
			  throw new Error('Invalid review date');
			}
		
			// Calculate the time difference in seconds
			const deltaSeconds = Math.round((reviewDate.getTime() - now.getTime()) / 1000);
		
			const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity];
		  
			// Array equivalent to the above but in the string representation of the units
			const units = ["second", "minute", "hour", "day", "week", "month", "year"];
		  
			// Grab the ideal cutoff unit
			const unitIndex = cutoffs.findIndex(cutoff => cutoff > Math.abs(deltaSeconds));
		  
			// Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
			const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;
		  
			const rtf = new Intl.RelativeTimeFormat('en-US', { numeric: "auto" });
			return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);	

		  } catch (error) {
			return 'Some time ago'; 
		  }
	}
	const getData = ()=>{
		const data = reviewData.feedback
		const tempElement = document.createElement('div');
		tempElement.innerHTML = data;
		const textContent = tempElement.textContent || tempElement.innerText;
		return textContent
	}

	if (!imageLoaded) {
		return <div className={styles.spinnerContainer}><Spinner /></div>;
	  }

	return (
		<div className={styles.review_box}>
			<div className={styles.review_head}>
				<div className={styles.reviewer_info}>
					<div className={styles.img}>
					{image && (
					<img
						src={image}
						alt=""
						onLoad={() => setImageLoaded(true)} 
						style={{ display: imageLoaded ? 'block' : 'none' }} 
					/>
					)}
					</div>
					<div className={styles.info}>
						<div className={styles.name}>{reviewData?.partner_id?.name}</div>
						<Rate rateVal={reviewData.rating} readOnly={true} />
					</div>
				</div>
				<div className={styles.time}>{calcDuration()}</div>
			</div>
			<p className={styles.review_txt}>{getData()}</p>
			{reviewData.verifiedPurchase && (
				<div className={styles.verified_purchase}>
					<div className={styles.icon}>
						<img src="/img/check.svg" alt="" />
					</div>
					<div className={styles.txt}>Verified Purchase</div>
				</div>
			)}
		</div>
	);
};

export default ReviewBox;
