import ProductService from "features/Products/ProductService";
import { useProductsSearchValuesStore } from "stores/useSearchValuesStore";
import { useEffect, useState } from "react";
import { useNavigate , useLocation } from 'react-router-dom';

// COMPONENTS
import BreadCrumbComp from "components/UI/BreadCrumbComp/BreadCrumbComp";
import PageTitle from "components/UI/PageTitle/PageTitle";
import Bannar from "components/UI/Bannar/Bannar";
import ProductCard from "components/UI/ProductCard/ProductCard";
import Pagination from "components/UI/Pagination/Pagination";
import Spinner from "components/UI/Spinner/Spinner";
// Child Components

import FilterSideBar from "./components/FilterSideBar/FilterSideBar";


// STYLES
import styles from "./Category.module.scss";



const Category = () => {
	// Bread Crumb
	const items = [
		{ label: "Home" },
		{ label: "All Products" }
	];
    const navigate = useNavigate();
	const location = useLocation();

	const searchValue = useProductsSearchValuesStore(state => state.searchValue)
	const filters = useProductsSearchValuesStore(state => state.searchFilters)
	const priceFilter = useProductsSearchValuesStore(state => state.searchFilters.priceFilter);
	const pageNumber = useProductsSearchValuesStore(state => state.pageNumber);

	const minPrice = priceFilter?.min; 
	const maxPrice = priceFilter?.max; 
	const categories = filters?.categories;

	const { isLoading, isFetching, data: allProducts } = ProductService.useFetchProduct(searchValue, filters, { minPrice, maxPrice });

	const setPageNumber = useProductsSearchValuesStore(state => state.setPageNumber);
	const setPriceFilter = useProductsSearchValuesStore(state => state.setPriceFilter);
	const updateSearchValue = useProductsSearchValuesStore(state => state.updateSearchValue);
	const setCategoriesFields = useProductsSearchValuesStore(state => state.setCategoriesFields);

	const updateUrlQueryParams = () => {
		const searchParams = new URLSearchParams();
	
		if (minPrice) searchParams.set('minPrice', minPrice);
		if (maxPrice) searchParams.set('maxPrice', maxPrice);
		if (searchValue) searchParams.set('search', searchValue);
		
		if (categories && categories.length > 0) {
			categories.forEach((category, index) => {
				searchParams.append('categories', category);
			});
		}
		if(pageNumber) searchParams.set('page', pageNumber);
		
		navigate(`?${searchParams.toString()}`, { replace: true });
	};
	useEffect(()=>{
		updateUrlQueryParams();
	},[searchValue,filters,pageNumber]);

	useEffect(() => {
		if(location.search === ''){
			updateSearchValue('');
			setPriceFilter(null, null);
			setCategoriesFields([]);
			setPageNumber(1);
			navigate('/shop/products', { replace: true });
		}else{
			const searchParams = new URLSearchParams(location.search);
			const minPrice = searchParams.get('minPrice');
			const maxPrice = searchParams.get('maxPrice');
			const search = searchParams.get('search');
			const categories = searchParams.getAll('categories');
			const page = searchParams.get('page');

			if (search !== null) {
			  updateSearchValue(search);
			}
			if (minPrice !== null && maxPrice !== null) {
			  setPriceFilter(minPrice, maxPrice);
			}
			if (categories.length > 0) {
			  setCategoriesFields(categories.map(category => ({ key: category })));
			}
			if (page) {
				setPageNumber(Number(page));
			  }
		}
	  }, [location.pathname , location.search]);

	return (
		<div className={styles.category}>
			<div className="container">
				{/* Bread Crumb */}
				<BreadCrumbComp items={items} />

				{/* Page Title */}
				<PageTitle title="All Products" />

				{/* Bannar */}
				<Bannar></Bannar>

				{/* Page Content */}
				<div className="row">
					<div className="col-lg-4 col-xl-3 mb-4 mb-lg-0">
						<FilterSideBar />
					</div>
					<div className="col-lg-8 col-xl-9">
						<div className={styles.products}>
							<div className="row">
							{
                                    (isLoading || isFetching) ?
                                    <Spinner /> : 
                                    allProducts?.map((product) => (
                                        <div className="col-md-6 col-xl-4" key={product.id}>
                                            <ProductCard product={product} />
                                        </div>
                                    ))
                                }
							</div>
						</div>

						<Pagination />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Category;
