import { useState , useEffect  } from "react";
import Cookies from "js-cookie";

// COMPONENTS
import DarkButton from "components/UI/DarkButton/DarkButton";

// Child Components
import CreditUpdatesBox from "./components/CreditUpdatesBox/CreditUpdatesBox";
import EditUserInfo from "./components/EditUserInfo/EditUserInfo";
import { useProfileStore } from "stores/useProfileStore";
// STYLES
import styles from "./ProfileIntro.module.scss";

const ProfileIntro = ({ profile }) => {
	const [editModal, setEditModal] = useState(false);
	const [imageSrc, setImageSrc] = useState(
		profile?.image + "&session_id=" + Cookies.get("front_session_id"))			
	const { updateProfile } = useProfileStore();

	const onUpdateProfile = async (updatedProfile) => {
	  updateProfile(updatedProfile);
	};
	
	  useEffect(() => {
			setImageSrc(
			  profile.image + "&session_id=" + Cookies.get("front_session_id")
			);
	  }, [profile]);

	const profileInfo = {
		image: "profile-img.png",
		userName: "Alaa Abd El Hafez",
		email: "Alaa123Hafez@gmial.com",
		phoneNumber: "+02 01123587254",
		totalCredit: 11000,
		spentPaid: 2000,
		availableCredit: 2000,
		inDiscusion: 222,
	};

	return (
		<>
			<div className={styles.profile_intro}>
				<div className={styles.profile_img}>
				<img key={imageSrc} src={imageSrc}/>
				</div>
				<div className={styles.profile_data}>
					<div className={styles.top_sec}>
						<div className={styles.info}>
							<div className="row">
								<div className="col-md-9 col-xl-4 mb-4 mb-xl-0">
									<div className={styles.info_box}>
										<div className={styles.tit}>Name</div>
										<div className={styles.val}>{profile?.name}</div>
									</div>
								</div>
								{profile?.email && <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
									<div className={styles.info_box}>
										<div className={styles.tit}>Email</div>
										<div className={styles.val}>{profile?.email}</div>
									</div>
								</div>}
								{profile?.phone && <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
									<div className={styles.info_box}>
										<div className={styles.tit}>Phone Number</div>
										<div className={styles.val}>{profile?.phone}</div>
									</div>
								</div>}
							</div>
						</div>
						<DarkButton
							btn
							type="button"
							label="Edit"
							icon="/img/edit.svg"
							withIcon
							outlined
							buttonClicked={() => setEditModal(true)}
						/>
					</div>
					<div className={styles.credit_updates}>
						<div className={styles.credit_updates_tit}>Credit Updates</div>
						<div className={styles.credit_boxes}>
							<div className="row">
								<div className="col-md-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
									<CreditUpdatesBox
										icon="totalCredit.svg"
										title="Total credit"
										value={`${profile.credit_limit ? profile.credit_limit : 0 } EGP`}
									/>
								</div>
								<div className="col-md-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
									<CreditUpdatesBox
										icon="spentPaid.svg"
										title="Spent paid"
										value={`${profile.credit_limit && profile.credit_limit_balance ? Math.abs(profile.credit_limit - profile.credit_limit_balance) : 0} EGP`}
									/>
								</div>
								<div className="col-md-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
									<CreditUpdatesBox
										icon="availableCredit.svg"
										title="Available credit"
										value={`${profile.credit_limit_balance ? profile.credit_limit_balance : 0 } EGP`}
									/>
								</div>
								<div className="col-md-6 col-lg-4 col-xl-3 mb-4 mb-xl-0">
									<CreditUpdatesBox
										icon="inDiscusion.svg"
										title="in discusion"
										value={profileInfo.inDiscusion}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Edit User Info */}
			<EditUserInfo
				profile={profile}
				showModal={editModal}
				hideModal={() => setEditModal(false)}
				onUpdateProfile={onUpdateProfile}
			/>
		</>
	);
};

export default ProfileIntro;
