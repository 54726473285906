import { Dialog } from "primereact/dialog";

// COMPONENTS
import DialogBtns from "../DialogBtns/DialogBtns";

// STYLES
import styles from "./DialogBox.module.scss";

const DialogBox = ({
	header,
	displayDialog,
	hideModal,
	children,
	buttonLabel,
	onConfirm,
	hidebtns
  }) => {
	return (
	  <Dialog
		className={styles.dialog_box}
		header={header}
		visible={displayDialog}
		style={{ width: '50vw' }}
		footer={
		  !hidebtns && (
			<DialogBtns
			  buttonLabel={buttonLabel}
			  closeDialog={hideModal}
			  btnClicked={onConfirm}
			/>
		  )
		}
		onHide={hideModal}
	  >
		{children}
	  </Dialog>
	);
  };

export default DialogBox;
