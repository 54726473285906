import axios from "lib/axios"

export const getProductRating = async (id , params) => {
    // return {"front_session_id": "1234567890", "user_name": "test"}
    return await axios.get(`/products/${id}/rating` , params)
}



export const addProductRatingApi = async (id , params) => {
    return await axios.post(`/products/${id}/add-rating` , params)
}