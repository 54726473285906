import { Link, useParams } from "react-router-dom";

// COMPONENTS
import Rank from "../Rank/Rank";
import GradientButton from "../GradientButton/GradientButton";
// import LikeButton from "../LikeButton/LikeButton";
// import CartButton from "../CartButton/CartButton";

// STYLES
import styles from "./ProductCard.module.scss";

const ProductCard = ({ product }) => {
	let { id } = useParams();
	return (
		product ? 
		<div className={styles.product_card}>
			<div className={styles.pro_img}>
				<Link to={`/shop/products/${product.id}`}>
          			<img src={product.image} alt={product.name} />
        		</Link>
				{product.difference ? <div className={styles.discount}>-{(product.original_price - product.price_after_discount) * 100 / product.original_price}% off</div> : ""}
			</div>
			<div className={styles.product_card_body}>
				<Link
					to={`/shop/products/${product.id}`}
					className={styles.product_name}
				>
					{product.name}
				</Link>
				<div className={styles.price_rate}>
					<div className={styles.price}>
						<div className={styles.afterDiscount}>
							{product.price_after_discount} EGP
						</div>
						{product.difference ? <div className={styles.original_price}>
							{product.original_price} EGP
						</div> : ""}
						
					</div>
					<Rank rankVal={Math.floor(product?.rating?.avg * 10) / 10} />
				</div>
				<div className={styles.card_btns}>
					{/* Buy Now Btn */}
					<GradientButton btn label="Buy Now" />

					{/* Like Btn */}
					{/* <LikeButton /> */}

					{/* Cart Btn */}
					{/* <CartButton /> */}
				</div>
			</div>
		</div> : null
	);
};

export default ProductCard;
