// COMPONENTS
import { Link } from "react-router-dom";
import GradientButton from "../GradientButton/GradientButton";

// STYLES
import styles from "./OfferCard.module.scss";

import OfferService from "features/Offers/OfferService"

const OfferCard = ({ offer }) => {

	const { isLoading: getOfferLoading, mutate: getOfferAction } = OfferService.useGetOffer();

	const onSubmit = async (values) =>  await getOfferAction(values)
	const reShapeName = (array) => {
		// debugger
		let res = ""
		array.forEach( (entry) => {
			res += entry.name + ', '
		} )
		return res.slice(0,-2);
	}
	return (
		<div className={styles.offer_card}>
			<div className={styles.offer_img}>
				<img src={`/img/offer3.png`} alt="" />
				{offer.bestOffer && (
					<div className={styles.best_offer}>
						<img src="/img/winner.svg" alt="" />
					</div>
				)}
			</div>
			<div className={styles.card_top}>
				<div className={styles.offer_logo}>
					<img src={`/img/amazon.svg`} alt="" />
				</div>
				<div className={styles.name_category}>
					<Link to={`/offer/${offer.id}`} className={styles.name}>
						{offer.name}
					</Link>
					<div className={styles.category}>
						{reShapeName(offer.product_ids)}
					</div>
				</div>
			</div>
			<div className={styles.card_bottom}>
				<div className={styles.price_date}>
					<div className={styles.price}>
						<div className={styles.after_discount}>
							${offer.product_ids[0] && offer.product_ids[0].lst_price}
						</div>
						<div className={styles.original_price}>
							$
							{offer.product_ids[0] &&
								(
									offer.product_ids[0].lst_price /
									(1 - offer.discount_percentage)
								).toFixed(2)}
						</div>
					</div>
					<div className={styles.expiredDate}>
						{/* <img src="/img/calendar.svg" alt="" /> */}
						<div className={styles.date}>Exp. {offer.date_to}</div>
					</div>
				</div>
				<div className={styles.offer_discount}>
					%{offer.discount_percentage * 100} Discount
				</div>
				<div className={styles.offer_desc}>{offer.description}</div>

				{/* Get Offer Btn */}
				<GradientButton loading={getOfferLoading} buttonClicked={() => onSubmit({"offer_id":offer.id})} type="button" btn label="Get Offer" />
			</div>
		</div>
	);
};

export default OfferCard;
