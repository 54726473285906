import { useQuery } from "react-query";
import { categoriesAPI } from "api/product";
import { useNavigate } from "react-router-dom";

// This is a custom hook that uses useMutation to fetch Product data and return it in response. 
// This hook is then used in Product component to sign the user in using the signIn function from useSignIn hook. 
// It also navigates the user to the homepage and shows a toast message.

const useFetchCategories = (params,options) =>  {
  

    return useQuery('products-categories',
      preFetchCategories,
        {
          select: data => {
            const responseData = data.data
            return responseData.data.map((category) => ({
            key: category.id,
            name: category.name,
            
          }))
        }
          ,
            ...options
        }
        )
}

// This function takes in parameters and passes them to a Product API function
// The function is named preFetchProduct
// The function takes in a parameter called params
// The function returns the output of the ProductAPI function

const preFetchCategories = () => {
  return categoriesAPI({})
}

const CategoryService = {
  useFetchCategories 
};

export default CategoryService;

