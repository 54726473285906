import { Carousel } from "primereact/carousel";

// COMPONENTS
import ProductCard from "components/UI/ProductCard/ProductCard";

// STYLES
import styles from "./RelatedProducts.module.scss";
import { useEffect, useState } from "react";

const RelatedProducts = ({ products }) => {

	const [relatedProducts , setRelatedProducts] = useState([]);

	useEffect(()=>{
		// window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        // });
		setRelatedProducts(products);
	})

	// Responsive Carousel Options
	const responsiveOptions = [
		{
			breakpoint: "1024px",
			numVisible: 4,
			numScroll: 1,
		},
		{
			breakpoint: "768px",
			numVisible: 2,
			numScroll: 1,
		},
		{
			breakpoint: "600",
			numVisible: 1,
			numScroll: 1,
		},
	];

	const productTemplate = (product) => {
		return <ProductCard product={product} />;
	};

	return (
		<div className={styles.related_products}>
			<div className={styles.related_products_title}>Related Products</div>
			<Carousel
				value={relatedProducts}
				numVisible={4}
				numScroll={1}
				responsiveOptions={responsiveOptions}
				itemTemplate={productTemplate}
				showNavigators={false}
			/>
		</div>
	);
};

export default RelatedProducts;
