// COMPONENTS
import DarkButton from "../DarkButton/DarkButton";

// STYLES
import styles from "./DialogBtns.module.scss";

const DialogBtns = ({ buttonLabel, closeDialog, btnClicked }) => {
	return (
	  <div className={styles.dialog_btns}>
		<DarkButton
		  btn
		  type="button"
		  label="Cancel"
		  outlined
		  buttonClicked={closeDialog}
		/>
		<DarkButton
		  btn
		  type="submit"
		  label={buttonLabel}
		  buttonClicked={btnClicked}
		/>
	  </div>
	);
  };

export default DialogBtns;
