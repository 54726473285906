import axios from "lib/axios"

export const productAPI = async (params) => {
    // return {"front_session_id": "1234567890", "user_name": "test"}
    return await axios.post("/products", params)
}

export const productDetailsAPI = async (id) => {
    // return {"front_session_id": "1234567890", "user_name": "test"}
    return await axios.post(`/products/${id}`, {})
}
// /api/products/categories

export const categoriesAPI = async (params) => {
    // return {"front_session_id": "1234567890", "user_name": "test"}
    return await axios.post("/products/categories", params)
}

export const productWithPaymentTermsAPI = async (id, params) => {
    return await axios.post(`/products/${id}/product`, params)
}