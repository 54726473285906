import { useMutation } from "react-query";
import { signupAPI } from "api/auth";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from 'js-cookie'

// This function is used to create a new user.
// The function takes in the options object as a parameter.
// The function uses the useMutation() hook to create a new user.
// The function uses the useSignIn() hook to sign in the newly created user.
// The function uses the useNavigate() hook to redirect the user to the home page after creating a new account.
// The function uses the toast.success() hook to display a success message after the user has signed up.
// The function returns the new user object.

const useFetchSignUp = (options) =>  {
    
    // const signIn = useSignIn();
    const navigate = useNavigate();
    
    return useMutation(
      preFetchSignUp,{
            onSuccess: (response) => {
                Cookies.set('front_session_id', response?.data?.session_id, { expires: 3600 })
                toast.success("Welcome to BanKKey!")
                navigate("/");
                },
            ...options
        }
        )
}

// This function calls the signup API with the data from the signup form.
// It takes one parameter, params, which is an object containing the user's name, email, and password.
// The function returns the response from the signup API.

const preFetchSignUp = (params) => {
  const data  = {
    name: params.fullName,
    email: params.email,
    phone: params.phoneNumber,
    password: params.password,
  }
  return signupAPI(data)
}


const SignUpService = {
  useFetchSignUp
};

export default SignUpService;
