import { useEffect } from "react";

import { Carousel } from "primereact/carousel";

// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

// COMPONENTS
import ProductCard from "components/UI/ProductCard/ProductCard";
import OfferSlider from "components/UI/OfferSlider/OfferSlider";

// STYLES
// import styles from "./ProductsSlider.module.scss";

const ProductsSlider = () => {
	useEffect(() => {
		AOS.init();
	}, []);

	// Responsive Carousel Options
	const responsiveOptions = [
		{
			breakpoint: "1024px",
			numVisible: 3,
			numScroll: 1,
		},
		{
			breakpoint: "992px",
			numVisible: 2,
			numScroll: 1,
		},
		{
			breakpoint: "768px",
			numVisible: 2,
			numScroll: 1,
		},
		{
			breakpoint: "600px",
			numVisible: 1,
			numScroll: 1,
		},
	];

	// Products
	const products = [
		{
			id: 1,
			image: "product1.png",
			name: "Dell Optiplex 9020 Small Form Business Desktop",
			price: 120,
			priceAfterDiscount: 200,
			discount: 17,
			rate: 4.3,
		},
		{
			id: 2,
			image: "product2.png",
			name: "Dell Optiplex 9020 Small Form Business Desktop",
			price: 120,
			priceAfterDiscount: 200,
			discount: 17,
			rate: 4.3,
		},
		{
			id: 3,
			image: "product3.png",
			name: "Dell Optiplex 9020 Small Form Business Desktop",
			price: 120,
			priceAfterDiscount: 200,
			discount: 17,
			rate: 4.3,
		},
		{
			id: 4,
			image: "product4.png",
			name: "Dell Optiplex 9020 Small Form Business Desktop",
			price: 120,
			priceAfterDiscount: 200,
			discount: 17,
			rate: 4.3,
		},
		{
			id: 5,
			image: "product5.png",
			name: "Dell Optiplex 9020 Small Form Business Desktop",
			price: 120,
			priceAfterDiscount: 200,
			discount: 17,
			rate: 4.3,
		},
		{
			id: 6,
			image: "product6.png",
			name: "Dell Optiplex 9020 Small Form Business Desktop",
			price: 120,
			priceAfterDiscount: 200,
			discount: 17,
			rate: 4.3,
		},
		{
			id: 7,
			image: "product7.png",
			name: "Dell Optiplex 9020 Small Form Business Desktop",
			price: 120,
			priceAfterDiscount: 200,
			discount: 17,
			rate: 4.3,
		},
		{
			id: 8,
			image: "product8.png",
			name: "Dell Optiplex 9020 Small Form Business Desktop",
			price: 120,
			priceAfterDiscount: 200,
			discount: 17,
			rate: 4.3,
		},
		{
			id: 9,
			image: "product2.png",
			name: "Dell Optiplex 9020 Small Form Business Desktop",
			price: 120,
			priceAfterDiscount: 200,
			discount: 17,
			rate: 4.3,
		},
	];

	const productTemplate = (product) => {
		return <ProductCard product={product} />;
	};

	return (
		<div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
			<OfferSlider>
				{products ? (
					<Carousel
						value={products}
						numVisible={4}
						numScroll={1}
						responsiveOptions={responsiveOptions}
						itemTemplate={productTemplate}
						showNavigators={false}
					/>
				) : null}
			</OfferSlider>
		</div>
	);
};

export default ProductsSlider;
