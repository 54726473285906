import { useState,useEffect } from "react";
import { Paginator } from "primereact/paginator";
import { useNavigate , useLocation } from 'react-router-dom';

// STYLES
import styles from "./Pagination.module.scss";

import { useProductsSearchValuesStore } from "stores/useSearchValuesStore";

const Pagination = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [basicFirst, setBasicFirst] = useState(0);
	const [basicRows, setBasicRows] = useState(10);
	
	const numberOfProducts = useProductsSearchValuesStore( state => state.numberOfProducts);
	const setPageNumber = useProductsSearchValuesStore( state => state.setPageNumber)
	const numberOfPages = useProductsSearchValuesStore(state => state.numberOfPages);
	const pageNumber = useProductsSearchValuesStore(state => state.pageNumber);


	
	const onBasicPageChange = (event) => {

		const newPageNumber = (Number(event.first) / event.rows) + 1;
		if (pageNumber !== newPageNumber) {
			setPageNumber(newPageNumber); 
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	};

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const pageFromUrl = parseInt(searchParams.get('page'), 10) || 1;
		const first = (pageFromUrl - 1) * basicRows;
	  
		setBasicFirst(first); 
		setPageNumber(pageFromUrl); 
	  
		window.scrollTo({
		  top: 0,
		  behavior: 'smooth'
		});
	  }, [location, basicRows, setPageNumber]);
	
	useEffect(() => {
		
		const searchParams = new URLSearchParams();
		if (pageNumber) searchParams.set('page', pageNumber);
	
		const currentUrlPage = new URLSearchParams(location.search).get('page');
		const currentPageNumber = currentUrlPage ? parseInt(currentUrlPage, 10) : 1;
	
		if (currentPageNumber !== pageNumber) {
			navigate(`?${searchParams.toString()}`, { replace: true });
		}
	}, [pageNumber, navigate, location.search]);

	useEffect(() => {
		if (numberOfProducts && numberOfPages) {
			setBasicRows(Math.ceil(numberOfProducts / numberOfPages));
		}
	}, [numberOfProducts, numberOfPages]);

	return (
		<div className={styles.pagination}>
			<Paginator	
				first={basicFirst}
				rows={basicRows}
				totalRecords={numberOfProducts}
				onPageChange={onBasicPageChange}
				
				// totalPages={numberOfPages}
			></Paginator>
		</div>
	);
};

export default Pagination;
